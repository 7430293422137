import enLocale from './locales/en.json'
import esLocale from './locales/es.json'

// TODO: There is a bug in the i18n module that prevents us from using the
// lowercase locale codes.
export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en: enLocale,
    EN: enLocale,
    es: esLocale,
    ES: esLocale,
  },
}))
